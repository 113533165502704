import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { Link } from 'gatsby';
import Formsy from 'formsy-react';
import {StripeCard} from '../components/form';
import {LoginButton} from '../app/user/components'
import {REGISTER} from "../constants/Paths";
import StripeInputManager from '../managers/stripe'
import * as Paths from '../constants/Paths';
import {DashboardLayoutA} from '../app/layouts'
import {purchasePremium} from '../state/account/actions'

class PremiumPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      cardError: null,
      processing: false,
      success: false
    }
  }

  onValidSubmit() {

    const { purchasePremium } = this.props;

    this.setState({processing: true, error: null, cardError: null});

    StripeInputManager()
      .createToken("premium")
      .then((token) => {
        purchasePremium(token.id)
          .then(() => this.setState({processing: false, error: null, success: true}))
          .catch((e) => this.setState({processing: false, error: e.message}));
      })
      .catch((e) => this.setState({cardError: e, processing: false, error: null}));

  }

  render() {

    const { loggedIn, user, hasActivePremium } = this.props;
    const { cardError, error, success, processing } = this.state;

    return (
      <DashboardLayoutA>
        <div className="PremiumPage">
          <section className="grid-restrain grid-row v-align full-height">
            <div className="grid-item-2 grid-row v-align">
              <div className="premium-desc-block">
                <p>Grow your test suite gracefully with these advanced features:</p>
                <ul>
                  <li>Auto-saving and syncing across all devices.</li>
                  <li>Simpler code generation via the CLI.</li>
                  <li>Persistance of results and other test data. (coming soon)</li>
                  <li>Cloud execution of tests. (coming soon)</li>
                </ul>
              </div>
            </div>
            {success ? (
              <div className="grid-item-3">
                <div className="card premium-card grid-row grid-column v-align h-align">
                  <div className="success-message">Thank you for your subscription!</div>
                  <div className="success-message-2 s2">You're SnapTest cloud account is ready to be used.</div>
                  <div>
                    <Link to={Paths.DB_USER_HOME} className="btn-primary">View Accounts</Link>
                  </div>
                </div>
              </div>
            ) : (hasActivePremium) ? (
              <div className="grid-item-3">
                <div className="card premium-card grid-row grid-column v-align h-align">
                  <div className="success-message">Looks like you already have a cloud subscription!</div>
                  <div>
                    <Link to={Paths.DB_USER_HOME} className="btn-primary">View Accounts</Link>
                  </div>
                </div>
              </div>
            ) : (
              <div className="grid-item-3">
                <div className="card premium-card">
                  <div className="grid-row h-align price-row">
                    <div className="price-period">SnapTest Cloud</div>
                    <div className="price-amount">$14.99</div>
                    <div className="price-permonth"> / mo</div>
                  </div>
                  {!loggedIn ? (
                    <div className="grid-item grid-row grid-column h-align v-align ">
                      <div className="for-account">
                        Account required to upgrade to a cloud account. Please <LoginButton text="login"/> or <Link to={REGISTER}>create an account</Link>.
                      </div>
                    </div>
                  ) : (
                    <div className="grid-item">
                      <div className="for-account">Subscribing for account: {user.email}.</div>
                      <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }>
                        <StripeCard name="cardToken"
                                    type="text"
                                    manager="premium"
                                    icon="credit-card"
                                    inputSize="pt-large"
                                    label="Credit Card Info"
                                    error={cardError}
                                    required />
                        <div className="confirmation-line">Your card will be charged $14.99.  Cloud features will become available immediately. Subscription can be managed or cancelled via the SnapTest dashboard.</div>
                        {error && (
                          <div className="pt-callout pt-intent-danger s4">
                            {error}
                          </div>
                        )}
                        <button className={"pt-button pt-intent-primary2 pt-large sr2" + (processing ? " pt-disabled" : "")} disabled={processing}>
                          {processing ? "+ Subscribing..." : "+ Subscribe"}
                        </button>
                        <Link to={Paths.DB_USER_HOME} className="btn-primary">View my accounts</Link>
                      </Formsy>
                    </div>
                  )}
                </div>
              </div>
            )}
          </section>
        </div>
      </DashboardLayoutA>
    )
  }

}

export default connect(
  (state) => {
    return {
      loggedIn: state.account.loggedIn,
      user: state.account.user,
      hasActivePremium: state.account.premium && state.account.premium.status === "active"
    }
  },
  (dispatch) => (
    bindActionCreators({
      purchasePremium
    }, dispatch)
  )
)(PremiumPage);